import { Router } from 'aurelia-router';
import { Api } from 'common/server';
import { Security } from 'common/security';
import { m } from 'common/models';
import { Notifier, Page, Logger } from 'common/ui';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';

export class ResetPassword {
	static inject = [Router, Security, Api, Notifier, Page, Logger, NewInstance.of(ValidationController)];

	email = '';
	emailSent = false;
	proveHuman = '';
	isProcessing = false;
	focusOnEmail = true;
	showForAdmin = false;

	constructor(router, security, api, notifier, page, logger, validationController) {
		this.router = router;
		this.security = security;
		this.api = api;
		this.notifier = notifier;
		this.page= page;
		this.logger = logger;
		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		this.showForAdmin = this.security.isAdmin;

		ValidationRules
            .ensure('email').required().email()
            .on(this);
    }

	activate() {
		this.page.track('reset-password');
	}

	async reset() {
		if (this.isProcessing) return;
		if (this.proveHuman.length > 0) return this.router.navigate('', true);
		try {
			const v = await this.validationController.validate();
		    if (!v.valid) return;

		    this.isProcessing = true;
		    let model = new m.ResetPassword();
		    model.email = this.email;

		    this.logger.logObject(model);

		    await this.api.post('member/reset-password', model);
			this.emailSent = true;
			this.isProcessing = false;
		} catch (err) {
			this.notifier.errorText(err);
		} finally {
			this.isProcessing = false;
		}
	}
} 
