import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import {Security} from 'common/security';
import {Api} from 'common/server';
import {m} from 'common/models';
import {c} from 'common/common';
import {Notifier, Page, Logger} from 'common/ui';
import {NewInstance} from 'aurelia-dependency-injection';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BootstrapFormValidationRenderer} from 'validation/bootstrap-form-validation-renderer';

@inject(Router, Security, Api, Notifier, Page, Logger, NewInstance.of(ValidationController))
export class ResetPasswordConfirm {

	isReset = false;
	email = '';
	newPassword = '';
	token = '';
	proveHuman = '';
	isProcessing = false;

	constructor(router, security, api, notifier, page, logger, validationController) {
	    this.router = router;
	    this.security = security;
		this.api = api;
		this.notifier = notifier;
		this.page= page;
		this.logger = logger;
		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());

		ValidationRules
            .ensure('email').required().email()
            .ensure('newPassword').required().minLength(6).matches(c.RegEx.strongPassword)
            .on(this);
    }

	activate(params, routerConfig) {
	    if (this.security.isAdmin) return;
		$('body').addClass('lpfn-bg');
		this.page.track('reset-password-confirm');
		var token = params.token;
		if (!token || token.length == 0) {
			return this.router.navigate("reset-password", true);
		}
		this.token = decodeURIComponent(token);
	}

    deactivate() {
		$('body').removeClass('lpfn-bg');
    }

    reset() {
		var me = this;
		if (me.isProcessing) return;
		if (me.proveHuman.length > 0) return this.router.navigate("", true);
		me.validationController.validate().then(v => {
		    if (!v.valid) return;

		    me.isProcessing = true;

		    var model = new m.ResetPasswordConfirmation();
		    model.email = me.email;
		    model.password = me.newPassword;
		    model.token = me.token;

		    me.logger.logObject(model);

		    me.api.postData("member/confirm-reset-password", model).then(data => {
		        me.isReset = true;
		        me.isProcessing = false;
		    }).catch(error => {
		        me.notifier.error('login-reset-password-error');
		        me.isProcessing = false;
		    });
		});
	}

} 
