import { Security } from 'common/security';
import { Router } from 'aurelia-router';

export class Logout {
    static inject = [Security, Router];

	constructor(security, router) {
		this.router = router;
		security.logout();
	}

    activate() {
        this.router.navigate('#/');
    }
} 

 