import { PLATFORM } from 'aurelia-pal';
import {inject} from 'aurelia-framework';
import {Router} from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import {Security} from 'common/security';
import {Api} from 'common/server';
import {m} from 'common/models';
import {Page, Notifier} from 'common/ui';
import {c} from 'common/common';
import { SetDirectUpline } from './dialog/set-direct-upline';
import {DialogService} from 'aurelia-dialog';
import {NewInstance} from 'aurelia-dependency-injection';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BootstrapFormValidationRenderer} from 'validation/bootstrap-form-validation-renderer';
import { Google } from 'services/google';
PLATFORM.moduleName('./dialog/set-direct-upline');

@inject(Router, EventAggregator, Security, Api, Page, Notifier, DialogService, NewInstance.of(ValidationController), Google)
export class SignUp {
	_ea;
    _google;

	registration = null;
	firstName = '';
	lastName = '';
	dateOfBirth = '';
	email = '';
	password = '';
	hidePassword = true;
	streetAddress = '';
	streetAddress2 = '';
	city = '';
	state = '';
	zip = '';
	cellPhone = '';
	// end properties
	directUplineEmailValidation = null;
	directUplineEmail = '';
	parent = null;
	knowParent = false;
	noParent = false;
	registrationProcessed = false;
	registrationSuccessful = false;
	isLoadingParent = false;
	showAdditionalLicenseOptions = false;
	agreedToTerms = false;
	hasValidationErrors = false;
	proveHuman = '';
	processing = false;

	thankYouModel = null;
	thankYouView = '';

	showForAdmin = false;

	usStates = [];

    google;
    placesearch;
    addressAutocompleteEl;
    autocomplete;
    componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name',
        postal_code_suffix: 'short_name'
    };

	constructor(router, ea, security, api, page, notifier, dialogService, validationController, google) {
		this.router = router;
		this._ea = ea;
		this.security = security;
		this.api = api;
		this.page = page;
		this.notifier = notifier;
		this.dialogService = dialogService;
		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._google = google;

		this.registration = new m.Registration();
		this.parent = new m.MiniMemberProfile();

		this.usStates = c.Geography.usStates;

		if (this.api.isDevelopment) {
			this.firstName = 'Nathaniel';
			this.lastName = 'Olson';
			this.dateOfBirth = '';
			this.email = 'nathaniel@njolson.net';
			this.password = 'Test12#';
			this.streetAddress = '430 Trent Jones Dr';
			this.streetAddress2 = '';
			this.city = 'Oxbow';
			this.state = 'ND';
			this.zip = '58047';
			this.cellPhone = '701-212-6640';
		}

		this.showForAdmin = this.security.isAdmin;

		ValidationRules
            .ensure('firstName').required()
            .ensure('lastName').required()
            .ensure('dateOfBirth').required()
	        .ensure('email').required().email()
            .ensure('password').required().minLength(6).matches(c.RegEx.strongPassword)
            .ensure('streetAddress').required()
            .ensure('city').required()
            .ensure('state').required()
            .ensure('zip').required()
            .ensure('cellPhone').required().satisfiesRule('phone')
            .ensure('agreedToTerms').equals(true)
            .on(this);
    }

	activate(params) {
        params = params || {};
		this.page.track('sign-up');
        if (params.firstName) this.firstName = params.firstName;
        if (params.lastName) this.lastName = params.lastName;
        if (params.emailAddress) this.email = params.emailAddress;
        if (params.dateOfBirth) this.dateOfBirth = params.dateOfBirth;
        if (params.address) this.streetAddress = params.address;
        if (params.city) this.city = params.city;
        if (params.state) this.state = params.state;
        if (params.zip) this.zip = params.zip;
        if (params.cell) this.cellPhone = params.cell;

		if (params.email) this._ea.publish(c.EventKeys.site.hideRegistrationLinks);

		this.showLinks = params.email ? false : true;
        let parentEmail = params.email;
		if (!parentEmail || parentEmail.length == 0) {
			this.noParent = true;
			return;
		}
		this.isLoadingParent = true;
		this._loadParent(parentEmail);
	}

	async attached() {
		if (this.noParent) {
		    this.openSetDirectUpline();
		}
		this.page.scrollToTop();
        this._initializePlacesAutocomplete();
    }

	openSetDirectUpline() {
	    const model = { email: '' };
	    this.dialogService.open({ viewModel: SetDirectUpline, model: model, ignoreTransitions: true }).whenClosed(response => {
	        if (response.wasCancelled) return;
	        this._loadParent(response.output.email);
	    });
	}

	async _loadParent(email) {
	    this.isLoadingParent = true;
		try {
		    const data = await this.api.get(`member/public?email=${encodeURIComponent(email)}`);
	        if (!data.isActive) {
	            this.notifier.error('register-direct-upline-not-found');
	            this.isLoadingParent = false;
	            this.registration.parentMemberId = null;
	            return;
	        }
	        this.knowParent = true;
	        this.noParent = false;
	        this.parent = data;
	        this.registration.parentMemberId = this.parent.id;
	    } catch (err) {
	        this.notifier.error('register-verify-direct-upline-email');
	        this.knowParent = false;
	        this.noParent = true;
	        this.parent = null;
	        this.registration.parentMemberId = null;
	    } finally {
	        this.isLoadingParent = false;
	    }
	}

	async register() {
		try {
			if (this.processing) return;
			if (this.proveHuman.length > 0) return this.router.navigate('', true);
	
			const v = await this.validationController.validate();
			if (!v.valid) {
				this.hasValidationErrors = true;
				this.page.scrollToTop();
				this.notifier.info('sign-up-validation-errors');
				return;
			}

			this.processing = true;
			this.hasValidationErrors = false;

			this.fillModel();

			await this.api.post('member', this.registration);
			this.registrationProcessed = true;
			this.registrationSuccessful = true;
			this.processing = false;
			this.thankYouModel = {};
			this.thankYouView = PLATFORM.moduleName('./thank-you');
			this.page.scrollToTop();
		} catch (err) {
			this.notifier.errorText(err);
			this.processing = false;
			this.page.scrollToTop();
		}
	}

	fillModel() {
		this.registration.firstName = this.firstName;
		this.registration.lastName = this.lastName;
		this.registration.dateOfBirth = this.dateOfBirth;
		this.registration.email = this.email;
		this.registration.password = this.password;
		this.registration.streetAddress = this.streetAddress;
		this.registration.streetAddress2 = this.streetAddress2;
		this.registration.city = this.city;
		this.registration.state = this.state;
		this.registration.zip = this.zip;
		this.registration.cellPhone = this.cellPhone;
	}

    async _initializePlacesAutocomplete() {
        try {
            await this._google.load();
            this.autocomplete = new google.maps.places.Autocomplete((this.addressAutocompleteEl), { types: ['geocode'] });
            this.autocomplete.addListener('place_changed', () => this.fillInAddress());
            this.geolocate();
        } catch (err) {
            console.log('GOOGLE initAutocomplete error', err);
        }
    }

    /**
     * Bias the autocomplete object to the user's geographical location, as supplied by the browser's 'navigator.geolocation' object.
     */
    geolocate() {
        try {
            if (!navigator.geolocation) return;
            navigator.geolocation.getCurrentPosition((position) => {
                const geolocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                const circle = new google.maps.Circle({
                    center: geolocation,
                    radius: position.coords.accuracy
                });
                this.autocomplete.setBounds(circle.getBounds());
            });
        } catch (err) {
            console.log('GEOLOCATE ERR', err);
        }
    }

    fillInAddress() {
        if (!this.autocomplete) return;
        try {
            // Get the place details from the autocomplete object.
            const place = this.autocomplete.getPlace();
        
            this.streetAddress = '';
            this.streetAddress2 = '';
            this.city = '';
            this.state = '';
            this.zip = '';

            let streetNumber = '';
            let street = '';
            for (let i = 0; i < place.address_components.length; i++) {
                var addressType = place.address_components[i].types[0];
                if (this.componentForm[addressType]) {
                    const val = place.address_components[i][this.componentForm[addressType]];
                    switch (addressType) {
                        case 'street_number': streetNumber = val; break;
                        case 'route': street = val; break;
                        case 'locality': this.city = val; break;
                        case 'administrative_area_level_1': this.state = val; break;
                        case 'postal_code': this.zip = val; break;
                    }
                }
            }
            this.streetAddress = `${streetNumber} ${street}`;
        } catch (err) {
            console.log('GOOGLE fill in address error', err);
        }
    }

	togglePasswordVisibility() {
		this.hidePassword = !this.hidePassword;
	}
} 
