import { Router } from 'aurelia-router';
import { Security, LoginResult } from 'common/security';
import { Page, Slideshow } from 'common/ui';
import { Api } from 'common/server';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { Bootstrapper } from 'common/bootstrapper';

export class Login {
    static inject = [Router, Security, Page, Slideshow, Api, NewInstance.of(ValidationController), Bootstrapper];
	security = null;
	heading = '';
	username = '';
	password = '';
	destination = '/members';
	validation = null;
	processing = false;
	registrationInvalidNoDirectUpline = false;

	constructor(router, security, page, slideshow, api, validationController, bootstrapper) {
	    this.router = router;
		this.security = security;
		this.page = page;
		this.slideshow = slideshow;
		this.api = api;
		this.validationController = validationController;
		this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._bootstrapper = bootstrapper;

		ValidationRules
            .ensure('username').required()
            .ensure('password').required()
            .on(this);
	}

	activate(params) {
        this.hideContent = false;
		this.api.resetSecurityCheck();
		if (params && params.origin) {
			this.destination = params.origin;
		}
		if (this.security.isAuthenticated) {
            if (!this.destination) this.destination = '#/members'
			this.router.navigate(this.destination, true);
		}

		this.page.track('login');
	}

	deactivate() {
        this.hideContent = true;
	}

	attached() {
	    this.page.scrollToTop();
        this.focusUsername = true;
	}

	async login() {
	    if (this.processing) return;
        const v = await this.validationController.validate();
        if (!v.valid) return;

        try {
			this.showLoginError = false;
	        this.processing = true;

	        let origin = this.security.getOrigin();
	        if (origin) this.destination = origin;
            if (!this.destination || this.destination === 'undefined') this.destination = '/members';

	        const result = await this.security.login(this.username, this.password);
            if (result === LoginResult.Authenticated) {
                await this._bootstrapper.forLogin();
				this._bootstrapper.forLoginBackground();
                if (!this.security.agent.onboardingComplete) {
                    this.router.navigate('#/members/onboarding', true);
                } else {
                    this.router.navigate(this.destination, true);
                }
            } else if (result === LoginResult.TermsNotAggreedTo) {
                this.router.navigate('#/writing-agent-agreement', true);
            } else if (result === LoginResult.InvalidRegistrationNoDirectUpline) {
                this.registrationInvalidNoDirectUpline = true;
            } else {
				this.showLoginError = true;
            }
	    } catch (err) {
            console.log(err);
        } finally {
            this.processing = false;
        }
	}
}
