import {inject} from 'aurelia-framework';
import {DialogController} from 'aurelia-dialog';
import {NewInstance} from 'aurelia-dependency-injection';
import {ValidationRules, ValidationController} from 'aurelia-validation';
import {BootstrapFormValidationRenderer} from 'validation/bootstrap-form-validation-renderer';

@inject(DialogController, NewInstance.of(ValidationController))
export class SetDirectUpline {

    directUplineEmail = '';

    constructor(dialogController, validationController) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());

        ValidationRules
            .ensure(m => m.directUplineEmail).required().email()
            .on(this);
    }

    activate(model) {
        this.directUplineEmail = '';
        this.validationController.reset();
    }

    setParent() {
        this.validationController.validate().then(v => {
            if (!v.valid) return;
            this.dialogController.ok({ email: this.directUplineEmail });
        });
    }
}