import {inject} from 'aurelia-framework';
import {Page} from 'common/ui';

@inject(Page)
export class PrivacyPolicy {
	constructor(page) {
		this.page = page;
	}

	activate() {
		this.page.track('terms-of-use');
	}

	attached() {
	    this.page.scrollToTop();
	}
} 
