import { activationStrategy } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Sites } from 'services/sites';
import { c } from 'common/common';
import environment from '../../config/environment.json';

export class Index {
    static inject = [EventAggregator, Sites];
    _ea;
    _sites;

    hideLinks;
    _handlers = [];

    constructor(ea, sites) {
        this._ea = ea;
        this._sites = sites;
    }

    activate() {
        this._handlers.push(this._ea.subscribe(c.EventKeys.site.hideRegistrationLinks, () => this.hideLinks = true));
    }

    deactivate() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    async attached() {
        this._applyTheme();
    }

    async _applyTheme() {
        try {
            this.theme = await this._sites.theme();
            this.logoImg = `${environment.static}${this.theme.logoPath}`;
        } catch (err) {
            console.log(err);
        }
    }

    determineActivationStrategy() {
        return activationStrategy.invokeLifecycle;
    }

    configureRouter(config, router){
        const navStrat = (instruction) => {
            switch (instruction.parentInstruction.config.route) {
                case 'sign-up':
                    instruction.config.moduleId = PLATFORM.moduleName('./sign-up');
                    break;
                case 'sign-up/:email':
                    instruction.config.moduleId = PLATFORM.moduleName('./sign-up');
                    break;
                case 'reset-password':
                    instruction.config.moduleId = PLATFORM.moduleName('./reset-password');
                    break;
                case 'reset-password-confirm/:token':
                    instruction.config.moduleId = PLATFORM.moduleName('./reset-password-confirm');
                    break;
                case 'logout':
                    instruction.config.moduleId = PLATFORM.moduleName('./logout');
                    break;
                case 'privacy-policy':
                    instruction.config.moduleId = PLATFORM.moduleName('./privacy-policy');
                    break;
                case 'terms-of-use':
                    instruction.config.moduleId = PLATFORM.moduleName('./terms-of-use');
                    break;
                default:
                    instruction.config.moduleId = PLATFORM.moduleName('./login');
                    break;
            }
            instruction.config.href = `#account${instruction.parentInstruction.fragment}`;
            delete instruction.config.viewPorts;
        };
		config.map([
				{
                    route: '',
                    navigationStrategy: navStrat,
                    settings: {},
                },
				{
                    route: 'login',
                    moduleId: PLATFORM.moduleName('./login'),
                    name: 'login',
                    settings: {},
                },
				{
                    route: ['sign-up', 'sign-up/:email'],
                    moduleId: PLATFORM.moduleName('./sign-up'),
                    name: 'sign-up',
                    settings: {},
                },
				{
                    route: 'reset-password',
                    moduleId: PLATFORM.moduleName('./reset-password'),
                    name: 'reset-password',
                    settings: {},
                },
				{
                    route: 'reset-password-confirm/:token',
                    moduleId: PLATFORM.moduleName('./reset-password-confirm'),
                    name: 'reset-password-confirm',
                    settings: {},
                },
				{
                    route: 'privacy-policy',
                    moduleId: PLATFORM.moduleName('./privacy-policy'),
                    name: 'privacy-policy',
                    settings: {},
                },
				{
                    route: 'terms-of-use',
                    moduleId: PLATFORM.moduleName('./terms-of-use'),
                    name: 'terms-of-use',
                    settings: {},
                },
				{
                    route: 'logout',
                    moduleId: PLATFORM.moduleName('./logout'),
                    name: 'logout',
                    settings: {},
                },
		]);

		this.router = router;
	}
}